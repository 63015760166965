/* Breakpoints */

$break-xs: 428px;
$break-sm: 768px;
$break-md: 1024px;
$break-lg: 1280px;
$break-xl: 1440px;

$modal-width-sm: 600px;
$modal-width-md: 900px;
$modal-width-lg: 1200px;
$modal-width-xl: 1536px;

/* COLORS */
$sustainment-blue: #66798e;
$white: #fff;

$super-light-gray: #f8f8fb;
$white-smoke: #efefef;
$light-gray: #cbc9c7;
$dark-gray: #53565a;
$sustainment-blue: #66798e;
$moss: #73969a;
$machine-mist: #bfd1d3;
$cold-smoke: #eef8fd;
$spark: #c05b52;
$gradient-dark: linear-gradient(104.17deg, #637280 3.6%, #497070 86.69%);
$gradient-light: linear-gradient(104.17deg, #637280 3.6%, #bbccc9 86.69%);
$white: #ffffff;
$primary-hover: #4e5a65;
$black: #000;
$action: #177abe;

$gray-900: #111827;
$gray-800: #1f2937;
$gray-700: #374151;
$gray-600: #4b5563;
$gray-500: #6b7280;
$gray-500: #6b7280;
$gray-400: #9ca3af;
$gray-300: #d1d5db;
$gray-200: #e5e7eb;
$gray-100: #f3f4f6;
$gray-50: #f9fafb;

$primary-lighter: #eaf4fb;
$primary-light-grey: #dfeaf4;
$primary-light: #2b8ed2;
$primary-normal: #177abe;
$primary-normal-translucent: #177abe66;
$primary-dark: #014f9a;

$secondary-light: #9fa3a6;
$secondary-normal: #8b8f92;
$secondary-dark: #464646;

$success-light: #79ae54;
$success-normal: #659a40;
$success-dark: #33691e;

$information-light: #31b8f0;
$information-normal: #1da4dc;
$information-dark: #0a90c8;

$attention-light: #f0a339;
$attention-normal: #dc8f25;
$attention-dark: #a9672a;

$danger-lightest: #fffaf8;
$danger-lighter: #fcf0eb;
$danger-light: #e66837;
$danger-normal: #d25423;
$danger-dark: #904214;

$bg-primary: #eaf4fb;
$bg-secondary: #daeaf4;
$bg-information: #eaf8fe;
$bg-success: #f2f7ee;
$bg-attention: #fef6eb;
$bg-danger: #fcf0eb;

$bg-success2: #e6efe0;
$bg-success3: #f3f7f0;
$bg-attention2: #f9eddc;
$bg-danger2: #f8e4dc;

$pill-danger: #f8e4dc;
$pill-text-danger: #d25423;
$pill-info: #daeaf4;
$pill-text-info: #177abe;

/* Shadow */
@mixin card-shadow() {
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
